import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Crypto Redux States
import {
  EMAIL_CONFIRM
} from "./actionTypes"

import { emailConfirmSuccess, emailConfirmFail } from "./actions"

//Include Both Helper File with needed methods
import {
  postEmailConfirm
} from "../../../helpers/fakebackend_helper"

function* emailConfirm({payload:id, payload:hash}) {
  try {
      const response = yield call(postEmailConfirm, id, hash)
      yield put(emailConfirmSuccess(response));
  } catch (error) {
      yield put(emailConfirmFail(error));
  }
}

function* confirmSaga() {
  yield takeEvery(EMAIL_CONFIRM, emailConfirm) 
}

export default confirmSaga
