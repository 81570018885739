import {
  EMAIL_CONFIRM,
  EMAIL_CONFIRM_SUCCESS, 
  EMAIL_CONFIRM_FAIL
} from "./actionTypes"

export const emailConfirm = (id, hash) => {
  return {
    type: EMAIL_CONFIRM,
    payload: { id, hash },
  }
}

export const emailConfirmSuccess = user => {
  return {
    type: EMAIL_CONFIRM_SUCCESS,
    payload: user,
  }
}
 

export const emailConfirmFail = error => {
  return {
    type: EMAIL_CONFIRM_FAIL,
    payload: error,
  }
}
 
